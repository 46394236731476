<template>
	<div class="page-wrapper">
        <div class="page-inner">
            <div class="container">
                <div class="index-head">
                    <div class="index-head__top">
                        <div class="index-head__profile profile-block">
                            <div class="profile-block__icon">{{ $store.state.data.home.name.substring(0, 1) }}</div>
                            <div class="profile-block__text">
                                <a href="#" class="profile-block__name">
                                    <span class="profile-block__name-value">{{ $store.state.data.home.name }}</span>
                                    <svg class="profile-block__name-icon" width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.8182 5.00006L0.647172 1.77029C0.290326 1.40684 0.297291 0.82244 0.6627 0.467594C1.02528 0.115491 1.60418 0.122393 1.95827 0.483039L3.77271 2.33109L6.5 5L3.8182 7.66893L2.0041 9.51662C1.64809 9.87922 1.06765 9.89107 0.697148 9.5433C0.314684 9.1843 0.302372 8.58095 0.669874 8.20665L3.8182 5.00006Z"
                                            fill="#333333"
                                        />
                                    </svg>
                                </a>
                                <div class="profile-block__status">
                                    <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.75" y="1.75" width="24.5" height="11.5" rx="5.75" fill="black" />
                                        <rect x="1.75" y="1.75" width="24.5" height="11.5" rx="5.75" stroke="#BDF050" stroke-width="3.5" />
                                        <path
                                            d="M8.93156 5.41002C9.27989 5.41002 9.58702 5.46808 9.85295 5.58419C10.1226 5.7003 10.3305 5.86323 10.4766 6.07297C10.6264 6.27898 10.7013 6.51869 10.7013 6.79211V6.81458C10.7013 7.26779 10.5552 7.61612 10.2631 7.85958C9.97468 8.09929 9.56455 8.21914 9.03269 8.21914H7.06069V9.56752H5.89209V5.41002H8.93156ZM8.75178 7.35955C8.95778 7.35955 9.11509 7.35206 9.22371 7.33708C9.33607 7.31835 9.42971 7.27153 9.50462 7.19662C9.58328 7.11797 9.62261 6.99249 9.62261 6.8202C9.62261 6.64416 9.58328 6.51682 9.50462 6.43816C9.42971 6.35576 9.3342 6.3052 9.21809 6.28647C9.10573 6.26774 8.95029 6.25838 8.75178 6.25838H8.5439C8.45776 6.25838 8.30794 6.26025 8.09444 6.26399C7.88469 6.26399 7.53824 6.26399 7.05507 6.26399V7.35955H8.75178Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M14.8513 9.56191L13.6996 8.21914H12.5254V9.56191H11.4017V5.4044H14.4412C14.7895 5.4044 15.0966 5.46246 15.3626 5.57857C15.6322 5.69468 15.8401 5.85761 15.9862 6.06736C16.136 6.27336 16.2109 6.51307 16.2109 6.78649V6.80896C16.2109 7.16479 16.106 7.45881 15.8963 7.69103C15.6903 7.92325 15.4038 8.07869 15.0367 8.15734L16.2446 9.56191H14.8513ZM15.0817 6.8202C15.0817 6.64042 15.0461 6.50933 14.9749 6.42692C14.9037 6.34452 14.812 6.29396 14.6996 6.27523C14.5872 6.2565 14.4281 6.24714 14.2221 6.24714C14.1322 6.24714 14.0554 6.24901 13.9917 6.25276C13.6846 6.26025 13.1958 6.26399 12.5254 6.26399V7.35955H14.2221C14.4281 7.35955 14.5872 7.35206 14.6996 7.33708C14.812 7.31835 14.9037 7.26966 14.9749 7.19101C15.0461 7.11235 15.0817 6.98875 15.0817 6.8202Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M19.4671 5.32013C19.969 5.32013 20.4203 5.41564 20.8211 5.60666C21.2219 5.79393 21.5365 6.05425 21.765 6.3876C21.9934 6.7172 22.1077 7.08988 22.1077 7.50563C22.1077 7.91763 21.9934 8.28844 21.765 8.61804C21.5365 8.94764 21.2219 9.20796 20.8211 9.39898C20.4203 9.58625 19.969 9.67989 19.4671 9.67989C18.9839 9.67989 18.5382 9.58625 18.1299 9.39898C17.7254 9.20796 17.4033 8.94764 17.1636 8.61804C16.9239 8.28469 16.804 7.91389 16.804 7.50563C16.804 7.09362 16.922 6.72095 17.158 6.3876C17.3977 6.05425 17.7217 5.79393 18.1299 5.60666C18.5382 5.41564 18.9839 5.32013 19.4671 5.32013ZM19.4671 8.76411C19.7105 8.76411 19.9409 8.71542 20.1581 8.61804C20.3754 8.51691 20.5514 8.37271 20.6862 8.18544C20.8211 7.99441 20.8885 7.76781 20.8885 7.50563C20.8885 7.24719 20.8211 7.02246 20.6862 6.83144C20.5514 6.63667 20.3754 6.48872 20.1581 6.3876C19.9409 6.28647 19.7105 6.2359 19.4671 6.2359C19.2161 6.2359 18.9802 6.28647 18.7592 6.3876C18.542 6.48498 18.3678 6.62918 18.2367 6.8202C18.1056 7.01122 18.0401 7.23595 18.0401 7.49439C18.0401 7.75658 18.1056 7.98505 18.2367 8.17982C18.3715 8.37084 18.5476 8.51691 18.7648 8.61804C18.9858 8.71542 19.2199 8.76411 19.4671 8.76411Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <button class="index-head__btn"><img src="../../assets/tinkoff/img/index-head-btn.png" alt="" class="index-head__btn-icon" /></button>
                    </div>
                    <form action="#" class="index-head__form">
                        <label class="search-block">
                            <div class="search-block__icon">
                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.5207 14.1626L10.9507 10.4496C11.8686 9.35844 12.3715 7.98552 12.3715 6.55624C12.3715 3.21688 9.65464 0.5 6.31528 0.5C2.97592 0.5 0.259033 3.21688 0.259033 6.55624C0.259033 9.8956 2.97592 12.6125 6.31528 12.6125C7.56892 12.6125 8.76358 12.2344 9.78498 11.5166L13.3821 15.2578C13.5325 15.4139 13.7347 15.5 13.9514 15.5C14.1565 15.5 14.3511 15.4218 14.4988 15.2796C14.8127 14.9776 14.8227 14.4768 14.5207 14.1626ZM6.31528 2.07989C8.78359 2.07989 10.7916 4.08793 10.7916 6.55624C10.7916 9.02456 8.78359 11.0326 6.31528 11.0326C3.84696 11.0326 1.83892 9.02456 1.83892 6.55624C1.83892 4.08793 3.84696 2.07989 6.31528 2.07989Z"
                                        fill="#838387"
                                    />
                                </svg>
                            </div>
                            <input class="search-block__input" type="search" placeholder="Реквизиты счета" />
                        </label>
                    </form>
                </div>
                <section class="random">
                    <swiper
                        v-bind="swiper_options"
                        class="swiper">
                        <swiper-slide v-for="item in $store.state.data.home.images.banners" v-if="item.status == 1" class="random__slide">
                            <a href="#" class="random-link">
                                <div class="random-link__inner" :style="{'background-image': `url(${$config.ApplicationPublicURL}/img/tinkoff/home/banners/${item.image})`}">{{ item.description }}</div>
                            </a>
                        </swiper-slide>
                    </swiper>
                </section>
                <div class="row">
                    <div class="primary-block operation-block">
                        <div class="primary-block__head">
                            <b class="primary-block__title">Все операции</b>
                            <p class="primary-block__subtext">{{ $store.state.data.home.spending_month }} <span class="operation-block__value">{{ $store.state.data.home.spending_month_value }}</span></p>
                        </div>
                        <div class="primary-block__body">
                            <svg class="shares-block" width="136" height="12" viewBox="0 0 136 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_202_19144)">
                                    <rect width="74" height="12" fill="#4FAECC" />
                                    <rect x="74" width="18" height="12" fill="#E8D245" />
                                    <rect x="92" width="15" height="12" fill="#A29AD1" />
                                    <rect x="107" width="8" height="12" fill="#E0908E" />
                                    <rect x="115" width="9" height="12" fill="#539A91" />
                                    <rect x="124" width="12" height="12" fill="#71C7E4" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_202_19144"><rect width="136" height="12" rx="6" fill="white" /></clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div class="primary-block">
                        <div class="primary-block__head">
                            <b class="primary-block__title" style="line-height: 112%;">
                                Кэшбэк<br />
                                и бонусы
                            </b>
                        </div>
                        <div class="primary-block__body">
                            <ul class="bonus-list">
                                <li class="bonus-list__item" v-for="item in $store.state.data.home.images.cashback" v-if="item.status == 1">
                                    <a href="#" class="bonus-list__link">
                                        <img :src="`${$config.ApplicationPublicURL}/img/tinkoff/home/cashback/${item.image}`" alt="" class="bonus-list__img" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="primary-block primary-advanced-block">
                    <div class="primary-advanced-block__icon"><img src="../../assets/tinkoff/img/primary-advanced-block-img-1.png" alt="" class="primary-advanced-block__icon-img" /></div>
                    <div class="primary-advanced-block__right">
                        <div class="primary-advanced-block__top">
                            <div class="primary-advanced-block__head">
                                <b class="primary-advanced-block__caption">{{ $store.state.data.home.balance }}</b>
                                <p class="primary-advanced-block__subtext">Tinkoff Black</p>
                            </div>
                            <div class="primary-advanced-block__bonus-caption bonus-caption">
                                <svg class="bonus-caption__icon" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M2.926 7.26705C1.93076 5.79984 1.46205 4.52822 0.76531 2.50947C0.713958 2.36068 0.874394 2.22758 1.01106 2.30567L3.36812 3.65256C3.49566 3.72544 3.65824 3.67622 3.72394 3.54483L5.15627 0.680158C5.21914 0.554426 5.39856 0.554426 5.46143 0.680158L6.89377 3.54483C6.95946 3.67622 7.12204 3.72544 7.24959 3.65256L9.58428 2.31845C9.72357 2.23886 9.88573 2.37807 9.82814 2.5278L8.52378 5.91912L7.86768 7.625H3.61309C3.33894 7.625 3.0799 7.49393 2.926 7.26705Z"
                                        fill="#E2E2E2"
                                    />
                                    <path d="M7.25083 3.65181L9.5842 2.31845C9.72348 2.23886 9.88564 2.37807 9.82805 2.5278L8.48258 6.02602L7.16378 4.01426C7.08293 3.89093 7.12279 3.72497 7.25083 3.65181Z" fill="#BDBDBD" />
                                </svg>
                                <span class="bonus-caption__text">{{ $store.state.data.home.points }}</span>
                            </div>
                        </div>
                        <div class="primary-advanced-block__body">
                            <ul class="card-list">
                                <li class="card-list__item" v-for="item in $store.state.data.home.cards">

                                    <template v-if="item.type.key == 'visa'">
                                        <a class="card-list__link" @click="toCard">
                                            <div class="card card_sm" :style="{'background-image': `url(${require('../../assets/tinkoff/img/card-bg-1.png')})`}">
                                                <div class="card__inner">
                                                    <div class="card__number">{{ item.number }}</div>
                                                    <div class="card__icon"><img src="../../assets/tinkoff/img/card-icon-1.svg" alt="" class="card__img" /></div>
                                                </div>
                                            </div>
                                        </a>
                                    </template>
                                    <template v-else-if="item.type.key == 'mastercard'">
                                        <a class="card-list__link" @click="toCard">
                                            <div class="card card_sm" :style="{'background-image': `url(${require('../../assets/tinkoff/img/card-bg-1.png')})`}">
                                                <div class="card__inner">
                                                    <div class="card__number">{{ item.number }}</div>
                                                    <div class="card__icon"><img src="../../assets/tinkoff/img/card-icon-1.svg" alt="" class="card__img" /></div>
                                                </div>
                                            </div>
                                        </a>
                                    </template>
                                    <template v-else-if="item.type.key == 'maestro'">
                                        <a class="card-list__link" @click="toCard">
                                            <div class="card card_sm" :style="{'background-image': `url(${require('../../assets/tinkoff/img/card-bg-1.png')})`}">
                                                <div class="card__inner">
                                                    <div class="card__number">{{ item.number }}</div>
                                                    <div class="card__icon"><img src="../../assets/tinkoff/img/card-icon-1.svg" alt="" class="card__img" /></div>
                                                </div>
                                            </div>
                                        </a>
                                    </template>
                                    <template v-else-if="item.type.key == 'mir'">
                                        <a class="card-list__link" @click="toCard">
                                            <div class="card card_sm" :style="{'background-image': `url(${require('../../assets/tinkoff/img/card-bg-2.png')})`}">
                                                <div class="card__inner">
                                                    <div class="card__number">{{ item.number }}</div>
                                                    <div class="card__icon"><img src="../../assets/tinkoff/img/card-icon-2.svg" alt="" class="card__img" /></div>
                                                </div>
                                            </div>
                                        </a>
                                    </template>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="primary-alert" :style="{'background-image': `url(${$config.ApplicationPublicURL}/img/tinkoff/home/bottom/${$store.state.data.home.images.bottom.image})`}" v-if="$store.state.data.home.images.bottom.status == 1">
                </div>
                <div class="primary-block primary-advanced-block">
                    <div class="primary-advanced-block__icon"><img src="../../assets/tinkoff/img/primary-advanced-block-img-2.png" alt="" class="primary-advanced-block__icon-img" /></div>
                    <div class="primary-advanced-block__right">
                        <div class="primary-advanced-block__head">
                            <b class="primary-advanced-block__caption">{{ $store.state.data.home.savings_balance }}</b>
                            <p class="primary-advanced-block__subtext">Накопительный счет</p>
                        </div>
                    </div>
                </div>
                <div class="primary-block primary-advanced-block">
                    <div class="primary-advanced-block__icon"><img src="../../assets/tinkoff/img/primary-advanced-block-img-3.png" alt="" class="primary-advanced-block__icon-img" /></div>
                    <div class="primary-advanced-block__right">
                        <div class="primary-advanced-block__head">
                            <b class="primary-advanced-block__caption">{{ $store.state.data.home.phones }}</b>
                            <p class="primary-advanced-block__subtext">Тинькофф Мобайл</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed-block">
            <nav class="main-menu">
                <ul class="main-menu__list">
                    <li class="main-menu__item">
                        <a href="#" class="main-menu__link">
                            <div class="main-menu__iconbox">
                                <svg class="main-menu__icon" width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_229_476)">
                                        <rect class="main-menu__icon-active-1" width="23" height="17" fill="#A3A8AE" />
                                        <path class="main-menu__icon-active-2" d="M17 0H23V17H13.5L17 0Z" fill="#D3D5D8" />
                                        <path
                                            d="M3.91027 8.12563C3.96658 8.04679 4.0575 8 4.15439 8H6.41704C6.66105 8 6.80299 8.27581 6.66116 8.47437L6.08973 9.27437C6.03342 9.35321 5.9425 9.4 5.84561 9.4H3.58296C3.33895 9.4 3.19701 9.12419 3.33884 8.92563L3.91027 8.12563Z"
                                            fill="#F4F7FE"
                                        />
                                        <path
                                            d="M3.91027 11.6256C3.96658 11.5468 4.0575 11.5 4.15439 11.5H8.41704C8.66106 11.5 8.80299 11.7758 8.66116 11.9744L8.08973 12.7744C8.03342 12.8532 7.9425 12.9 7.84561 12.9H3.58296C3.33894 12.9 3.19701 12.6242 3.33884 12.4256L3.91027 11.6256Z"
                                            fill="#F4F7FE"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_229_476"><path d="M0 5C0 2.23858 2.23858 0 5 0H23V12C23 14.7614 20.7614 17 18 17H0V5Z" fill="white" /></clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span class="main-menu__text">Главная</span>
                            <div class="main-menu__count" v-if="$store.state.data.home.notifications.home">{{ $store.state.data.home.notifications.home }}</div>
                        </a>
                    </li>
                    <li class="main-menu__item">
                        <a href="#" class="main-menu__link">
                            <div class="main-menu__iconbox">
                                <svg class="main-menu__icon" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="main-menu__icon-active-1" cx="10.5" cy="10" r="10" fill="#A3A8AE" />
                                    <circle class="main-menu__icon-active-2" cx="10.5" cy="10" r="5" fill="#D3D5D8" />
                                </svg>
                            </div>
                            <span class="main-menu__text">Платежи</span>
                            <div class="main-menu__count" v-if="$store.state.data.home.notifications.payments">{{ $store.state.data.home.notifications.payments }}</div>
                        </a>
                    </li>
                    <li class="main-menu__item">
                        <a href="#" class="main-menu__link">
                            <div class="main-menu__iconbox">
                                <svg class="main-menu__icon" width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="main-menu__icon-active-2" d="M0 10L10 0C10 0 12 3 11 5.5C10.1795 7.55113 7.5 10 7.5 10C7.5 10 10.1795 12.4489 11 14.5C12 17 10 20 10 20L0 10Z" fill="#A3A8AE" />
                                    <path
                                        class="main-menu__icon-active-1"
                                        d="M24.0601 10L14.0601 20C14.0601 20 12.0601 17 13.0601 14.5C13.8805 12.4489 16.5601 10 16.5601 10C16.5601 10 13.8805 7.55113 13.0601 5.5C12.0601 3 14.0601 -8.74228e-07 14.0601 -8.74228e-07L24.0601 10Z"
                                        fill="#AFB3BA"
                                    />
                                </svg>
                            </div>
                            <span class="main-menu__text">Город</span>
                            <div class="main-menu__count" v-if="$store.state.data.home.notifications.city">{{ $store.state.data.home.notifications.city }}</div>
                        </a>
                    </li>
                    <li class="main-menu__item">
                        <a href="#" class="main-menu__link">
                            <div class="main-menu__iconbox">
                                <svg class="main-menu__icon" width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2202_177)">
                                        <path class="main-menu__icon-active-1" d="M0.5 0H22.5V16H0.5V0Z" fill="#A3A8AE" />
                                        <path class="main-menu__icon-active-2" d="M4 10.1C4 9.21635 4.71634 8.5 5.6 8.5H10.5V9.6C10.5 10.4837 9.78366 11.2 8.9 11.2H4V10.1Z" fill="#AFB3BA" />
                                        <path d="M4 5.1C4 4.21635 4.71634 3.5 5.6 3.5H13.5V4.6C13.5 5.48365 12.7837 6.2 11.9 6.2H4V5.1Z" fill="#F5F5F5" />
                                    </g>
                                    <path class="main-menu__icon-active-1" d="M17.5 15H22.5V18L20 16.5L17.5 15Z" fill="#A3A8AE" />
                                    <defs>
                                        <clipPath id="clip0_2202_177"><path d="M0.5 0H18.5C20.7091 0 22.5 1.79086 22.5 4V15.5H4.5C2.29086 15.5 0.5 13.7091 0.5 11.5V0Z" fill="white" /></clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span class="main-menu__text">Чат</span>
                            <div class="main-menu__count" v-if="$store.state.data.home.notifications.chat">{{ $store.state.data.home.notifications.chat }}</div>
                        </a>
                    </li>
                    <li class="main-menu__item main-menu__item_active">
                        <a href="#" class="main-menu__link">
                            <div class="main-menu__iconbox">
                                <svg class="main-menu__icon" width="29" height="10" viewBox="0 0 29 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="main-menu__icon-active-3" d="M0 5C0 2.23858 2.23858 0 5 0H7.7V5C7.7 7.76142 5.46142 10 2.7 10H0V5Z" fill="#A3A8AE" />
                                    <path class="main-menu__icon-active-1" d="M10.2 5C10.2 2.23858 12.4385 0 15.2 0H17.9V5C17.9 7.76142 15.6614 10 12.9 10H10.2V5Z" fill="#A3A8AE" />
                                    <path class="main-menu__icon-active-1" d="M20.3999 5C20.3999 2.23858 22.6385 0 25.3999 0H28.0999V5C28.0999 7.76142 25.8613 10 23.0999 10H20.3999V5Z" fill="#A3A8AE" />
                                </svg>
                            </div>
                            <span class="main-menu__text">Еще</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>


<style>
  @import '../../assets/tinkoff/css/main.min.css';
</style>

<script>
    import { Navigation, Pagination, Autoplay, Thumbs } from 'swiper'
    import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

    SwiperCore.use([Navigation, Pagination, Autoplay, Thumbs])

    export default {
        head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'Тинькофф'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'Тинькофф'
                },
                {
                    name: 'author', 
                    content: 'Тинькофф'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/tinkoff/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/tinkoff/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        components: {
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
                swiper_options: {
                    slidesPerView: 'auto',
                    spaceBetween: 4
                }
            };
        },
        methods: {
            toCard() {
                this.$router.replace({
                   name: "TinkoffCard",
                   params: { 0: `/${this.$route.path}` },
                   replace: true,
                });

                //this.$router.push({name: 'TinkoffCard'})
            },
        }
    }
</script>