import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: {
      home: {
        name: null
      }
    },
  },
  mutations: {
    setData(state, value) {
      state.data = value
    },
  }
})