<template>
	<div class="page-wrapper">
        <div class="card-head">
            <div class="card-head__inner container">
                <div class="card-head__top">
                    <div class="card-head__info">
                        <div class="card-head__info-name">{{ $store.state.data.card.name }}</div>
                        <div class="card-head__info-amount">{{ $store.state.data.card.balance }}</div>
                    </div>
                    <ul class="card-list card-list_xl">
                        <li class="card-list__item" v-for="item in $store.state.data.card.cards">

                            <template v-if="item.type.key == 'visa'">
                                <a href="#" class="card-list__link">
                                    <div class="card" :style="{'background-image': `url(${require('../../assets/tinkoff/img/card-bg-1.png')})`}">
                                        <div class="card__inner">
                                            <div class="card__number">{{ item.number }}</div>
                                            <div class="card__icon"><img src="../../assets/tinkoff/img/card-icon-1.svg" alt="" class="card__img" /></div>
                                        </div>
                                    </div>
                                </a>
                            </template>
                            <template v-if="item.type.key == 'mastercard'">
                                <a href="#" class="card-list__link">
                                    <div class="card" :style="{'background-image': `url(${require('../../assets/tinkoff/img/card-bg-1.png')})`}">
                                        <div class="card__inner">
                                            <div class="card__number">{{ item.number }}</div>
                                            <div class="card__icon"><img src="../../assets/tinkoff/img/card-icon-1.svg" alt="" class="card__img" /></div>
                                        </div>
                                    </div>
                                </a>
                            </template>
                            <template v-if="item.type.key == 'maestro'">
                                <a href="#" class="card-list__link">
                                    <div class="card" :style="{'background-image': `url(${require('../../assets/tinkoff/img/card-bg-1.png')})`}">
                                        <div class="card__inner">
                                            <div class="card__number">{{ item.number }}</div>
                                            <div class="card__icon"><img src="../../assets/tinkoff/img/card-icon-1.svg" alt="" class="card__img" /></div>
                                        </div>
                                    </div>
                                </a>
                            </template>
                            <template v-if="item.type.key == 'mir'">
                                <a href="#" class="card-list__link">
                                    <div class="card" :style="{'background-image': `url(${require('../../assets/tinkoff/img/card-bg-2.png')})`}">
                                        <div class="card__inner">
                                            <div class="card__number">{{ item.number }}</div>
                                            <div class="card__icon"><img src="../../assets/tinkoff/img/card-icon-2.svg" alt="" class="card__img" /></div>
                                        </div>
                                    </div>
                                </a>
                            </template>
                        </li>
                        <li class="card-list__item">
                            <button class="card-list__add">
                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.16675 5.66667V1.50001C5.16675 1.03977 5.53984 0.666672 6.00008 0.666672V0.666672C6.46032 0.666672 6.83341 1.03977 6.83341 1.50001V5.66667H11.0001C11.4603 5.66667 11.8334 6.03977 11.8334 6.5V6.5C11.8334 6.96024 11.4603 7.33334 11.0001 7.33334H6.83341V11.5C6.83341 11.9602 6.46032 12.3333 6.00008 12.3333V12.3333C5.53984 12.3333 5.16675 11.9602 5.16675 11.5V7.33334H1.00008C0.539844 7.33334 0.166748 6.96024 0.166748 6.5V6.5C0.166748 6.03977 0.539844 5.66667 1.00008 5.66667H5.16675Z"
                                        fill="white"
                                    />
                                </svg>
                            </button>
                        </li>
                    </ul>
                </div>
                <ul class="card-head__operation card-operation">
                    <li class="card-operation__item">
                        <button class="card-operation__btn">
                            <svg class="card-operation__icon" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12.5" cy="12" r="12" fill="#6C9BF3" />
                                <circle cx="12.5" cy="12" r="6" fill="#C2D6F9" />
                            </svg>
                            <p class="card-operation__caption">Оплатить</p>
                        </button>
                    </li>
                    <li class="card-operation__item">
                        <button class="card-operation__btn">
                            <svg class="card-operation__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_204_941)" />
                                <g clip-path="url(#clip0_204_941)">
                                    <path
                                        d="M13.0125 18H10.9852C10.7789 18 10.6102 17.8313 10.6102 17.625V13.3875H6.375C6.16875 13.3875 6 13.2188 6 13.0125V10.9852C6 10.7789 6.16875 10.6102 6.375 10.6102H10.6125V6.375C10.6125 6.16875 10.7813 6 10.9875 6H13.0148C13.2211 6 13.3898 6.16875 13.3898 6.375V10.6125H17.625C17.8313 10.6125 18 10.7813 18 10.9875V13.0148C18 13.2211 17.8313 13.3898 17.625 13.3898H13.3875V17.625C13.3875 17.8313 13.2188 18 13.0125 18Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <linearGradient id="paint0_linear_204_941" x1="10" y1="9.5" x2="19" y2="22" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#7EA7F4" />
                                        <stop offset="1" stop-color="#8EB2F5" />
                                    </linearGradient>
                                    <clipPath id="clip0_204_941"><rect width="12" height="12" fill="white" transform="translate(6 6)" /></clipPath>
                                </defs>
                            </svg>
                            <p class="card-operation__caption">Пополнить</p>
                        </button>
                    </li>
                    <li class="card-operation__item">
                        <button class="card-operation__btn">
                            <svg class="card-operation__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
                                <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_204_948)" />
                                <path d="M17 12L13 8M17 12L13 16M17 12H7" stroke="white" stroke-width="2" />
                                <defs>
                                    <linearGradient id="paint0_linear_204_948" x1="10" y1="9.5" x2="19" y2="22" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#7EA7F4" />
                                        <stop offset="1" stop-color="#8EB2F5" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <p class="card-operation__caption">Перевести</p>
                        </button>
                    </li>
                </ul>
                <div class="card-head__circle"></div>
                <div class="card-head__line">
                    <svg width="393" height="190" viewBox="0 0 393 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_204_749)"><rect x="-86" y="30" width="553" height="130" fill="url(#paint0_linear_204_749)" /></g>
                        <defs>
                            <filter id="filter0_f_204_749" x="-116" y="0" width="613" height="190" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_204_749" />
                            </filter>
                            <linearGradient id="paint0_linear_204_749" x1="236.304" y1="30" x2="236.304" y2="127.5" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0.75" />
                                <stop offset="0.413981" stop-color="white" />
                                <stop offset="1" stop-color="white" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
        <div class="card-wrapper">
            <div class="container">
                <div class="row">
                    <div class="primary-block operation-block" @click="openHistory">
                        <div class="primary-block__head">
                            <b class="primary-block__title">
                                Операции<br />
                                по счету
                            </b>
                            <p class="primary-block__subtext">{{ $store.state.data.card.spending_month }} <span class="operation-block__value">{{ $store.state.data.card.spending_month_value }}</span></p>
                        </div>
                        <div class="primary-block__body">
                            <svg class="shares-block" width="136" height="12" viewBox="0 0 136 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_202_19144)">
                                    <rect width="74" height="12" fill="#4FAECC" />
                                    <rect x="74" width="18" height="12" fill="#E8D245" />
                                    <rect x="92" width="15" height="12" fill="#A29AD1" />
                                    <rect x="107" width="8" height="12" fill="#E0908E" />
                                    <rect x="115" width="9" height="12" fill="#539A91" />
                                    <rect x="124" width="12" height="12" fill="#71C7E4" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_202_19144"><rect width="136" height="12" rx="6" fill="white" /></clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div class="primary-block cashback-block">
                        <div class="primary-block__head">
                            <div class="bonus-caption bonus-caption_xl">
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M2.70632 8.83033C1.53948 7.11016 0.989954 5.6193 0.173089 3.25248C0.112883 3.07804 0.30098 2.92199 0.461204 3.01355L3.22465 4.59266C3.37419 4.67811 3.5648 4.62039 3.64182 4.46635L5.32111 1.10777C5.39482 0.960361 5.60518 0.960361 5.67889 1.10777L7.35818 4.46635C7.4352 4.62039 7.62581 4.67811 7.77535 4.59266L10.5126 3.02853C10.6759 2.93521 10.866 3.09843 10.7985 3.27397L9.26923 7.25L8.5 9.25H3.51187C3.19045 9.25 2.88675 9.09633 2.70632 8.83033Z"
                                        fill="#E2E2E2"
                                    />
                                    <path d="M7.7769 4.59177L10.5126 3.02853C10.6759 2.93521 10.866 3.09843 10.7985 3.27397L9.22102 7.37534L7.67485 5.01672C7.58006 4.87213 7.62679 4.67755 7.7769 4.59177Z" fill="#BDBDBD" />
                                </svg>
                                <span class="bonus-caption__text" style="margin-top: 2px;">{{ $store.state.data.card.points }}</span>
                            </div>
                            <span class="cashback-block__text">Накоплено кэшбэка</span>
                        </div>
                        <div class="cashback-block__clear">
                            Зачислится<br />
                            {{ $store.state.data.card.сlean_up }}
                        </div>
                    </div>
                </div>
                <div class="primary-block alert-block">
                    <div class="alert-block__left">
                        <svg class="alert-block__icon" width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.439941 10.0888C0.439941 22.8256 16.9999 31.3792 16.9999 31.3792C16.9999 31.3792 33.5599 22.8256 33.5599 10.0888C33.5599 4.8616 29.3263 0.628 24.0991 0.628C21.2551 0.628 18.7351 1.9096 16.9999 3.8896C15.2647 1.9024 12.7447 0.628 9.90074 0.628C4.67354 0.6208 0.439941 4.8616 0.439941 10.0888Z"
                                fill="#E13C31"
                            />
                        </svg>
                        <p class="alert-block__text">
                            Расскажем, что есть на экране<br />
                            вашего счета
                        </p>
                    </div>
                    <button class="alert-block__close">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" rx="12" fill="#F6F7F8" />
                            <path
                                d="M12 10.5858L15.5355 7.05025C15.926 6.65972 16.5592 6.65972 16.9497 7.05025C17.3402 7.44077 17.3402 8.07394 16.9497 8.46446L13.4142 12L16.9497 15.5355C17.3402 15.9261 17.3402 16.5592 16.9497 16.9497C16.5592 17.3403 15.926 17.3403 15.5355 16.9497L12 13.4142L8.46443 16.9497C8.07391 17.3403 7.44074 17.3403 7.05022 16.9497C6.65969 16.5592 6.65969 15.9261 7.05022 15.5355L10.5858 12L7.05022 8.46446C6.65969 8.07394 6.65969 7.44077 7.05022 7.05025C7.44074 6.65972 8.07391 6.65972 8.46443 7.05025L12 10.5858Z"
                                fill="#9398A1"
                            />
                        </svg>
                    </button>
                </div>
                <div class="primary-block primary-slider-block bonus-block">
                    <div class="primary-slider-block__head"><b class="primary-slider-block__caption">Бонусы по Tinkoff Black </b><a href="#" class="primary-slider-block__link">Все</a></div>
                    <div class="primary-slider-block__body swiper">
                        <ul class="bonus-block__list bonus-list swiper-wrapper">
                            <li class="bonus-item swiper-slide">
                                <b class="bonus-item__caption">
                                    Кэшбэк<br />
                                    до 30%
                                </b>
                                <img src="../../assets/tinkoff/img/bonus-block-1.png" alt="" class="bonus-item__img" />
                            </li>
                            <li class="bonus-item swiper-slide">
                                <b class="bonus-item__caption">
                                    Повышенный<br />
                                    кэшбэк<br />
                                    в апреле
                                </b>
                                <img src="../../assets/tinkoff/img/bonus-block-2.png" alt="" class="bonus-item__img" />
                            </li>
                            <li class="bonus-item swiper-slide">
                                <b class="bonus-item__caption">
                                    Кэшбэк<br />
                                    в разделе<br />
                                    Город
                                </b>
                                <img src="../../assets/tinkoff/img/bonus-block-3.png" alt="" class="bonus-item__img" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed-block">
            <nav class="main-menu">
                <ul class="main-menu__list">
                    <li class="main-menu__item">
                        <a href="#" class="main-menu__link">
                            <div class="main-menu__iconbox">
                                <svg class="main-menu__icon" width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_229_476)">
                                        <rect class="main-menu__icon-active-1" width="23" height="17" fill="#A3A8AE" />
                                        <path class="main-menu__icon-active-2" d="M17 0H23V17H13.5L17 0Z" fill="#D3D5D8" />
                                        <path
                                            d="M3.91027 8.12563C3.96658 8.04679 4.0575 8 4.15439 8H6.41704C6.66105 8 6.80299 8.27581 6.66116 8.47437L6.08973 9.27437C6.03342 9.35321 5.9425 9.4 5.84561 9.4H3.58296C3.33895 9.4 3.19701 9.12419 3.33884 8.92563L3.91027 8.12563Z"
                                            fill="#F4F7FE"
                                        />
                                        <path
                                            d="M3.91027 11.6256C3.96658 11.5468 4.0575 11.5 4.15439 11.5H8.41704C8.66106 11.5 8.80299 11.7758 8.66116 11.9744L8.08973 12.7744C8.03342 12.8532 7.9425 12.9 7.84561 12.9H3.58296C3.33894 12.9 3.19701 12.6242 3.33884 12.4256L3.91027 11.6256Z"
                                            fill="#F4F7FE"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_229_476"><path d="M0 5C0 2.23858 2.23858 0 5 0H23V12C23 14.7614 20.7614 17 18 17H0V5Z" fill="white" /></clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span class="main-menu__text">Главная</span>
                            <div class="main-menu__count" v-if="$store.state.data.card.notifications.home">{{ $store.state.data.card.notifications.home }}</div>
                        </a>
                    </li>
                    <li class="main-menu__item">
                        <a href="#" class="main-menu__link">
                            <div class="main-menu__iconbox">
                                <svg class="main-menu__icon" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="main-menu__icon-active-1" cx="10.5" cy="10" r="10" fill="#A3A8AE" />
                                    <circle class="main-menu__icon-active-2" cx="10.5" cy="10" r="5" fill="#D3D5D8" />
                                </svg>
                            </div>
                            <span class="main-menu__text">Платежи</span>
                            <div class="main-menu__count" v-if="$store.state.data.card.notifications.payments">{{ $store.state.data.card.notifications.payments }}</div>
                        </a>
                    </li>
                    <li class="main-menu__item">
                        <a href="#" class="main-menu__link">
                            <div class="main-menu__iconbox">
                                <svg class="main-menu__icon" width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="main-menu__icon-active-2" d="M0 10L10 0C10 0 12 3 11 5.5C10.1795 7.55113 7.5 10 7.5 10C7.5 10 10.1795 12.4489 11 14.5C12 17 10 20 10 20L0 10Z" fill="#A3A8AE" />
                                    <path
                                        class="main-menu__icon-active-1"
                                        d="M24.0601 10L14.0601 20C14.0601 20 12.0601 17 13.0601 14.5C13.8805 12.4489 16.5601 10 16.5601 10C16.5601 10 13.8805 7.55113 13.0601 5.5C12.0601 3 14.0601 -8.74228e-07 14.0601 -8.74228e-07L24.0601 10Z"
                                        fill="#AFB3BA"
                                    />
                                </svg>
                            </div>
                            <span class="main-menu__text">Город</span>
                            <div class="main-menu__count" v-if="$store.state.data.card.notifications.city">{{ $store.state.data.card.notifications.city }}</div>
                        </a>
                    </li>
                    <li class="main-menu__item">
                        <a href="#" class="main-menu__link">
                            <div class="main-menu__iconbox">
                                <svg class="main-menu__icon" width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2202_177)">
                                        <path class="main-menu__icon-active-1" d="M0.5 0H22.5V16H0.5V0Z" fill="#A3A8AE" />
                                        <path class="main-menu__icon-active-2" d="M4 10.1C4 9.21635 4.71634 8.5 5.6 8.5H10.5V9.6C10.5 10.4837 9.78366 11.2 8.9 11.2H4V10.1Z" fill="#AFB3BA" />
                                        <path d="M4 5.1C4 4.21635 4.71634 3.5 5.6 3.5H13.5V4.6C13.5 5.48365 12.7837 6.2 11.9 6.2H4V5.1Z" fill="#F5F5F5" />
                                    </g>
                                    <path class="main-menu__icon-active-1" d="M17.5 15H22.5V18L20 16.5L17.5 15Z" fill="#A3A8AE" />
                                    <defs>
                                        <clipPath id="clip0_2202_177"><path d="M0.5 0H18.5C20.7091 0 22.5 1.79086 22.5 4V15.5H4.5C2.29086 15.5 0.5 13.7091 0.5 11.5V0Z" fill="white" /></clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span class="main-menu__text">Чат</span>
                            <div class="main-menu__count" v-if="$store.state.data.card.notifications.chat">{{ $store.state.data.card.notifications.chat }}</div>
                        </a>
                    </li>
                    <li class="main-menu__item main-menu__item_active">
                        <a href="#" class="main-menu__link">
                            <div class="main-menu__iconbox">
                                <svg class="main-menu__icon" width="29" height="10" viewBox="0 0 29 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="main-menu__icon-active-3" d="M0 5C0 2.23858 2.23858 0 5 0H7.7V5C7.7 7.76142 5.46142 10 2.7 10H0V5Z" fill="#A3A8AE" />
                                    <path class="main-menu__icon-active-1" d="M10.2 5C10.2 2.23858 12.4385 0 15.2 0H17.9V5C17.9 7.76142 15.6614 10 12.9 10H10.2V5Z" fill="#A3A8AE" />
                                    <path class="main-menu__icon-active-1" d="M20.3999 5C20.3999 2.23858 22.6385 0 25.3999 0H28.0999V5C28.0999 7.76142 25.8613 10 23.0999 10H20.3999V5Z" fill="#A3A8AE" />
                                </svg>
                            </div>
                            <span class="main-menu__text">Еще</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="primary-modal hide" ref="primaryModal">
            <div class="primary-modal__inner">
                <div class="operation-history">
                    <div class="operation-history__top">
                        <div class="operation-history__head">
                            <button class="operation-history__close" @click="closeHistory">Закрыть</button>
                            <div class="operation-history__caption">Операции</div>
                        </div>
                        <form action="#" class="operation-history__form">
                            <label class="search-block">
                                <div class="search-block__icon">
                                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.5207 14.1626L10.9507 10.4496C11.8686 9.35844 12.3715 7.98552 12.3715 6.55624C12.3715 3.21688 9.65464 0.5 6.31528 0.5C2.97592 0.5 0.259033 3.21688 0.259033 6.55624C0.259033 9.8956 2.97592 12.6125 6.31528 12.6125C7.56892 12.6125 8.76358 12.2344 9.78498 11.5166L13.3821 15.2578C13.5325 15.4139 13.7347 15.5 13.9514 15.5C14.1565 15.5 14.3511 15.4218 14.4988 15.2796C14.8127 14.9776 14.8227 14.4768 14.5207 14.1626ZM6.31528 2.07989C8.78359 2.07989 10.7916 4.08793 10.7916 6.55624C10.7916 9.02456 8.78359 11.0326 6.31528 11.0326C3.84696 11.0326 1.83892 9.02456 1.83892 6.55624C1.83892 4.08793 3.84696 2.07989 6.31528 2.07989Z"
                                            fill="#838387"
                                        />
                                    </svg>
                                </div>
                                <input class="search-block__input" type="search" placeholder="Поиск" />
                            </label>
                        </form>
                        <div class="operation-history__tags tags-block">
                            <ul class="tags-block__list">
                                <li class="tags-block__item tag">
                                    <span class="tag__text">{{ $store.state.data.card.name }} </span>
                                    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.70587 4.99999L8.73633 8.03045C9.07107 8.36519 9.07107 8.9079 8.73633 9.24263C8.4016 9.57737 7.85888 9.57737 7.52415 9.24263L4.49369 6.21218L1.46323 9.24263C1.1285 9.57737 0.585786 9.57737 0.251051 9.24263C-0.0836838 8.9079 -0.0836835 8.36519 0.251052 8.03045L3.28151 4.99999L0.251051 1.96954C-0.0836838 1.6348 -0.0836838 1.09209 0.251051 0.757353C0.585786 0.422618 1.1285 0.422618 1.46323 0.757353L4.49369 3.78781L7.52415 0.757353C7.85888 0.422618 8.4016 0.422618 8.73633 0.757353C9.07107 1.09209 9.07107 1.6348 8.73633 1.96954L5.70587 4.99999Z"
                                            fill="white"
                                        />
                                    </svg>
                                </li>
                            </ul>
                            <button class="tags-block__add">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.987305" width="24" height="24" rx="12" fill="#CED1D5" />
                                    <path
                                        d="M13.8444 11.1429L18.1302 11.1429C18.6035 11.1429 18.9873 11.5266 18.9873 12C18.9873 12.4734 18.6035 12.8571 18.1302 12.8571H13.8444L13.8444 17.1429C13.8444 17.6162 13.4607 18 12.9873 18C12.5139 18 12.1302 17.6162 12.1302 17.1429L12.1302 12.8571H7.84445C7.37106 12.8571 6.9873 12.4734 6.9873 12C6.9873 11.5266 7.37106 11.1429 7.84445 11.1429L12.1302 11.1429V6.85714C12.1302 6.38376 12.5139 6 12.9873 6C13.4607 6 13.8444 6.38376 13.8444 6.85714V11.1429Z"
                                        fill="white"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div class="operation-history__wastes wastes-block">
                            <div class="wastes-block__head">
                                <div class="wastes-block__caption">Траты за <span class="wastes-block__month">{{ $store.state.data.history.spending_month }}</span></div>
                                <button class="wastes-block__dropdown">
                                    {{ $store.state.data.history.spending_month_value }}
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.99997 7.8182L10.2297 4.64717C10.5932 4.29033 11.1776 4.29729 11.5324 4.6627C11.8845 5.02528 11.8776 5.60418 11.517 5.95827L9.66894 7.77271L7.00003 10.5L4.3311 7.8182L2.48341 6.0041C2.12081 5.64809 2.10896 5.06765 2.45673 4.69715C2.81573 4.31468 3.41908 4.30237 3.79338 4.66987L6.99997 7.8182Z"
                                            fill="#5597FA"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <svg class="wastes-block__shares" width="361" height="12" viewBox="0 0 361 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_210_1285)">
                                    <rect width="64" height="12" fill="#4FAECC" />
                                    <rect x="64" width="125" height="12" fill="#E8D245" />
                                    <rect x="189" width="15" height="12" fill="#A29AD1" />
                                    <rect x="204" width="41" height="12" fill="#E0908E" />
                                    <rect x="245" width="52" height="12" fill="#539A91" />
                                    <rect x="297" width="64" height="12" fill="#71C7E4" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_210_1285"><rect width="361" height="12" rx="6" fill="white" /></clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div class="operation-history__body">
                        <ul class="operation-history-list">
                            <li class="operation-history-list__item" v-for="item in $store.state.data.history.history">
                                <b class="operation-history-list__caption">{{ item.date }}</b>
                                <div class="operation-history-item" v-for="transaction in item.transactions" v-if="transaction.status == 1">
                                    <img :src="`${$config.ApplicationPublicURL}/img/tinkoff/history/transactions/${transaction.image}`" alt="" class="operation-history-item__img" />
                                    <div class="operation-history-item__right">
                                        <div class="operation-history-item__top">
                                            <div class="operation-history-item__name">{{ transaction.name }}</div>
                                            <div class="operation-history-item__price" :class="{'transaction_in__amount_color': transaction.amount.includes('+')}">{{ transaction.amount }}</div>
                                        </div>
                                        <div class="operation-history-item__bottom">
                                            <div class="operation-history-item__category">{{ transaction.description }}</div>
                                            <div class="operation-history-item__payment">
                                                <div class="cashback" v-if="transaction.cashback">{{ transaction.cashback }}</div>
                                                {{ transaction.card }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style>
  @import '../../assets/tinkoff/css/main.min.css';
</style>

<script>
    export default {
        head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'Тинькофф'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'Тинькофф'
                },
                {
                    name: 'author', 
                    content: 'Тинькофф'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/tinkoff/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/tinkoff/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        data() {
            return {
                
            };
        },
        methods: {
            openHistory() {
                this.$refs.primaryModal.classList.remove('hide')
            },
            closeHistory() {
                this.$refs.primaryModal.classList.add('hide')
            }
        }
    }
</script>