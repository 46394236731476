import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from './events/Store'

import Http from './events/Http'
import Config from './events/Config'

import TinkoffLogin from './components/tinkoff/Login'
import TinkoffIndex from './components/tinkoff/Index'
import TinkoffCard from './components/tinkoff/Card'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: '',
    linkExactActiveClass: '',
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            //path: '/tinkoff/login',
            path: '/tinkoff',
            name: 'TinkoffLogin',
            component: TinkoffLogin
        },
        {
            //path: '/tinkoff/index',
            path: '*',
            name: 'TinkoffIndex',
            component: TinkoffIndex
        },
        {
            //path: '/tinkoff/card',
            path: '*',
            name: 'TinkoffCard',
            component: TinkoffCard
        }
    ]
})

router.beforeEach(async (to, from, next) => {

    var data = Store.state.data

    if(!data.home.name) {
        await Http.post(`${Config.ApplicationAPI}/tinkoff/item`)
        .then((response) => {
            response = response.data
            switch (response.status) {
                case 'error':
                    console.log('get item error')
                    break
                case 'success':

                    Store.commit('setData', response.data)
                    
                    break
                default:
                    console.log('Unknown error')
            }
        }).catch((error) => {
        })
    }

    next()

})


export default router