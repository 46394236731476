import Vue from 'vue'
import router from './router'
import http from './events/Http'
import store from './events/Store'
import config from './events/Config'

import App from './App.vue'

import VueTippy, { TippyComponent } from 'vue-tippy'
import VueHead from 'vue-head'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import Moment from 'moment-timezone'
import 'moment/locale/ru'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

Vue.prototype.$config = config
Vue.prototype.$axios = http

Vue.use(VueTippy)
Vue.use(VueHead)
Vue.use(VueToast, {
    position: 'top-right',
    duration: 3000
})
Vue.use(VueCookies, {
    expires: '1y'
})


Moment.tz.setDefault(config.Timezone)
Moment.locale('ru')
Vue.prototype.$moment = Moment

Vue.component('tippy', TippyComponent)
Vue.component('v-select', vSelect)


Vue.mixin({
    methods: {
        setData (data) {
            store.commit('setData', data)
        }
    }
})

new Vue({
    el: '#app',
    router,
    store,
    computed: {
        
    },
    watch: {
        
    },
    render: (h) => h(App)
})
